import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./style.css";

// Import required modules
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";

import ExecutiveMemberHighlight from "./ExecutiveMemberHighlight";
import { Card, styled } from "@mui/material";

import President from "../../../../pictures/ExecutiveBoard/President.png";
import VicePresident from "../../../../pictures/ExecutiveBoard/InternalVP.png";
import ExternalPres from "../../../../pictures/ExecutiveBoard/ExternalVP.png";
import Treasurer from "../../../../pictures/ExecutiveBoard/Treasurer.png";
import Secretary from "../../../../pictures/ExecutiveBoard/Secretary.png";

import PresText from "../../../../pictures/ExecutiveBoard/PresText.png";
import InternalText from "../../../../pictures/ExecutiveBoard/InternalText.png";
import ExternalText from "../../../../pictures/ExecutiveBoard/ExternalText.png";
import SecretaryText from "../../../../pictures/ExecutiveBoard/SecretaryText.png";
import TreasurerText from "../../../../pictures/ExecutiveBoard/TreasurerText.png";

const CustomPositionCard = styled(Card)(({ theme }) => ({
  background: "#7F5F49",
  borderRadius: "20px",
}));

export default function ExecutiveSwiper() {
  return (
    <>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 10,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={{ clickable: true }}
        keyboard={{
          enabled: true
        }}
        navigation={true}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <CustomPositionCard elevation={5}>
            <ExecutiveMemberHighlight
              key="president"
              image={President}
              imageText={PresText}
            />
          </CustomPositionCard>
        </SwiperSlide>
        <SwiperSlide>
          <CustomPositionCard elevation={5}>
            <ExecutiveMemberHighlight
              key="internal-vp"
              image={VicePresident}
              imageText={InternalText}
            />
          </CustomPositionCard>
        </SwiperSlide>
        <SwiperSlide>
          <CustomPositionCard elevation={5}>
            <ExecutiveMemberHighlight
              key="external-vp"
              image={ExternalPres}
              imageText={ExternalText}
            />
          </CustomPositionCard>
        </SwiperSlide>
        <SwiperSlide>
          <CustomPositionCard elevation={5}>
            <ExecutiveMemberHighlight
              key="treasurer"
              image={Treasurer}
              imageText={TreasurerText}
            />
          </CustomPositionCard>
        </SwiperSlide>
        <SwiperSlide>
          <CustomPositionCard elevation={5}>
            <ExecutiveMemberHighlight
              key="secretary"
              image={Secretary}
              imageText={SecretaryText}
            />
          </CustomPositionCard>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
