/**
 * author: Patrick Vyn Badiang
 */
import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from "@mui/material";

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useState } from "react";
import FormsList from "./FormsList";
import EventsList from "./EventsList";
import SlidesList from "./SlidesList";

function AdminLogin() {

    const [showPassword, setShowPassword] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginState, setLoginState] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };

    const handleLogin = () => {

        if (username === "admin" && password === "fasaonmefasaon3") {
            setLoginState(true);
            alert("Login Successful!");
        }
    }

    return (
        <div sx={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
            {/*When not Logged In */}
            { !loginState ?   (<Box sx={{display: "flex", flexDirection: "column", padding: "20px", width: "20%", margin: "auto", justifyContent: "center", alignItems: "center"}}>
                <Typography variant="h3" color="primary">
                Admin Login</Typography>
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-username">Username</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-username"
                        type='text'                    
                        label="Username"
                        onChange={(e) => setUsername(e.target.value)}/>
                </FormControl>
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label={
                                showPassword ? 'hide the password' : 'display the password'
                            }
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            onMouseUp={handleMouseUpPassword}
                            edge="end"
                            color="primary"
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        
                        }
                        onChange={(e) => setPassword(e.target.value)}
                        label="Password"/>
                </FormControl>
                <Button variant="outlined" color="primary"
                    onClick={handleLogin}
                >Login</Button>  
                </Box>)
                : (
                <>
                   
                    <FormsList/>
                    <EventsList/>
                    <SlidesList/>
                    <Box sx={{height:"20px"}}/>
                    <Button variant="outlined" color="primary" href="/" onClick={() => setLoginState(false)}>
                        <Typography variant="h6" color="primary" >
                            Logout
                        </Typography>
                    </Button>
                </>)    
                }
        </div>
    );
}

export default AdminLogin;