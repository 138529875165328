import { Paper, Typography, CircularProgress } from "@mui/material";
import FASAFormPage from "./FormLayout";
import Section from "../mainPage/Section";
import { useEffect, useState } from "react";

function LinksPage() {
  const styles = {
    container: {
      position: "relative",
      width: "100%",
      marginTop: "0px",
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      marginTop: "100px",
    },
    paper: {
      position: "absolute",
      bottom: "40px",
      left: "50%",
      transform: "translateX(-50%)",
      width: "fit-content",
      padding: "20px",
    },
  };

  const [formsData, setFormsData] = useState([]);
  const [picturesData, setPicturesData] = useState([]);
  const [slidesData, setSlidesData] = useState([]);

  const [loadingForms, setLoadingForms] = useState(true);
  const [loadingPictures, setLoadingPictures] = useState(true);
  const [loadingSlides, setLoadingSlides] = useState(true);

  // Fetch forms data
  useEffect(() => {
    fetch("https://nehr0pj0y1.execute-api.us-east-1.amazonaws.com/fasa-forms")
      .then((response) => response.json())
      .then((data) => {
        setFormsData(data);
        setLoadingForms(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingForms(false);
      });
  }, []);

  // Fetch pictures data
  useEffect(() => {
    fetch("https://nehr0pj0y1.execute-api.us-east-1.amazonaws.com/fasa-events")
      .then((response) => response.json())
      .then((data) => {
        setPicturesData(data);
        setLoadingPictures(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingPictures(false);
      });
  }, []);

  // Fetch slides data
  useEffect(() => {
    fetch("https://nehr0pj0y1.execute-api.us-east-1.amazonaws.com/fasa-slides")
      .then((response) => response.json())
      .then((data) => {
        setSlidesData(data);
        setLoadingSlides(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingSlides(false);
      });
  }, []);

  // If any data is still loading, show a spinner.
  if (loadingForms || loadingPictures || loadingSlides) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div style={styles.container}>
        <Paper style={styles.paper}>
          <Typography
            variant="h3"
            sx={{
              fontSize: {
                xs: "1rem", // Extra-small devices (phones)
                sm: "1.5rem", // Small devices (tablets)
                md: "2rem", // Medium devices (desktops)
                lg: "3rem", // Large devices (large desktops)
              },
            }}
          >
            Links
          </Typography>
        </Paper>
      </div>
      <div style={{ marginLeft: 30, marginTop: 50 }} />

      <Section text="FASA Forms" />
      <FASAFormPage forms={formsData} />
      {/* Fasa Fit data is now within FasaData */}

      <div style={{ marginLeft: 30, marginTop: 50 }} />
      <Section text="Pictures" />
      <FASAFormPage forms={picturesData} />

      <div style={{ marginLeft: 30, marginTop: 50 }} />
      <Section text="Slides" />
      <FASAFormPage forms={slidesData} />
    </>
  );
}

export default LinksPage;
