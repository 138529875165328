/**
 * author: Patrick Vyn Badiang
 */
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

function FormsList() {
  const [forms, setForms] = useState([]);
  const [selectedFormId, setSelectedFormId] = useState(null);
  const [selectedFormName, setSelectedFormName] = useState(''); // Added to store the name
  const [isEditing, setIsEditing] = useState(false);
  const [editFormData, setEditFormData] = useState({ name: '', link: '' }); // Use lowercase for state fields
  const [isAdding, setIsAdding] = useState(false); // State for adding new forms
  const [newFormData, setNewFormData] = useState({ name: '', link: '' }); // State for new form data

  // Fetch forms on component load
  useEffect(() => {
    fetch('https://nehr0pj0y1.execute-api.us-east-1.amazonaws.com/fasa-forms')
      .then((response) => response.json())
      .then((data) => setForms(data))
      .catch((error) => console.error(error));
  }, []);

  // Handle selecting a form
  const handleSelect = (id) => {
    setSelectedFormId(id === selectedFormId ? null : id); // Deselect if clicked again
    const selectedForm = forms.find((form) => form.id === id);
    setSelectedFormName(selectedForm ? selectedForm.name : ''); // Store the name when selected
  };

  // Handle delete button click
  const handleDelete = () => {
    if (!selectedFormId) {
      alert('Please select a form to delete.');
      return;
    }

    // Use the selectedFormName in the delete request
    fetch(`https://nehr0pj0y1.execute-api.us-east-1.amazonaws.com/fasa-forms/${selectedFormId}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name: selectedFormName }), // Use the stored name for deletion
    })
      .then((response) => response.json())
      .then(() => {
        setForms(forms.filter((form) => form.id !== selectedFormId)); // Remove from state
        setSelectedFormId(null);
        setSelectedFormName(''); // Clear the selected form name
        alert('Form deleted successfully!');
      })
      .catch((error) => console.error(error));
  };

  // Handle edit button click
  const handleEdit = () => {
    if (!selectedFormId) {
      alert('Please select a form to edit.');
      return;
    }

    const selectedForm = forms.find((form) => form.id === selectedFormId);
    setEditFormData(selectedForm);
    setIsEditing(true);
  };

  // Handle editing form submission
  // Edit form submission
    const handleEditSubmit = (e) => {
    e.preventDefault();
    // console.log('Sending edit data:', editFormData);

    fetch(`https://nehr0pj0y1.execute-api.us-east-1.amazonaws.com/fasa-forms/${selectedFormId}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ originalName: selectedFormName,name: editFormData.name, link: editFormData.link }), // Correct field names
    })
      .then((response) => response.json())
      .then(() => {
        // Fetch the updated list of forms from the backend
        return fetch('https://nehr0pj0y1.execute-api.us-east-1.amazonaws.com/fasa-forms');
      })
      .then((response) => response.json())
      .then((data) => {
        setForms(data); // Update the state with the new list of forms
        setIsEditing(false); // Close the modal
        setSelectedFormName(editFormData.name); // Update the selected form name
        setNewFormData({ name: '', link: '' }); // Clear the form inputs
        alert('Form updated successfully!');
      })
      
      .catch((error) => console.error(error));
  };

  // Handle adding form submission
  const handleAddSubmit = (e) => {
    e.preventDefault();
    fetch('https://nehr0pj0y1.execute-api.us-east-1.amazonaws.com/fasa-forms', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name: newFormData.name, link: newFormData.link }),
    })
      .then((response) => response.json())
      .then(() => {
        // Fetch the updated list of forms from the backend
        return fetch('https://nehr0pj0y1.execute-api.us-east-1.amazonaws.com/fasa-forms');
      })
      .then((response) => response.json())
      .then((data) => {
        setForms(data); // Update the state with the new list of forms
        setIsAdding(false); // Close the modal
        setNewFormData({ name: '', link: '' }); // Clear the form inputs
        alert('Form added successfully!');
      })
      .catch((error) => console.error(error));
  };
  

  return (
    <div>
      <Typography variant="h2" color="primary">All Forms</Typography>
      <ul style={{ listStyle: 'none', padding: 0 }}>
        {forms.map((form) => (
          <li
            key={form.id}
            style={{
              padding: '10px',
              margin: '5px 0',
              border: '1px solid #ccc',
              borderRadius: '4px',
              backgroundColor: selectedFormId === form.id ? '#d3d3d3' : '#fff',
              cursor: 'pointer',
            }}
            onClick={() => handleSelect(form.id)}
          >
            <strong>{form.name}</strong> -{' '}
            <a href={form.link} target="_blank" rel="noopener noreferrer">
              {form.link}
            </a>
          </li>
        ))}
      </ul>

      <div style={{ marginTop: '20px' }}>
        <button onClick={() => setIsAdding(true)} style={{ marginRight: '10px' }}>
          Add
        </button>
        <button
          onClick={handleEdit}
          style={{ marginRight: '10px' }}
          disabled={!selectedFormId}
        >
          Edit
        </button>
        <button onClick={handleDelete} disabled={!selectedFormId}>
          Delete
        </button>
      </div>

      {/* Add Form Modal */}
      {isAdding && (
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fff', padding: '20px', border: '1px solid #ccc', borderRadius: '4px', zIndex: 1000 }}>
          <h2>Add New Form</h2>
          <form onSubmit={handleAddSubmit}>
            <div style={{ marginBottom: '10px' }}>
              <label>
                Name:
                <input
                  type="text"
                  value={newFormData.name}
                  onChange={(e) => setNewFormData({ ...newFormData, name: e.target.value })}
                  required
                  style={{ marginLeft: '10px' }}
                />
              </label>
            </div>
            <div style={{ marginBottom: '10px' }}>
              <label>
                Link:
                <input
                  type="url"
                  value={newFormData.link}
                  onChange={(e) => setNewFormData({ ...newFormData, link: e.target.value })}
                  required
                  style={{ marginLeft: '10px' }}
                />
              </label>
            </div>
            <button type="submit" style={{ marginRight: '10px' }}>
              Save
            </button>
            <button onClick={() => setIsAdding(false)}>Cancel</button>
          </form>
        </div>
      )}

      {/* Edit Form Modal */}
      {isEditing && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#fff',
            padding: '20px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            zIndex: 1000,
          }}
        >
          <h2>Edit Form</h2>
          <form onSubmit={handleEditSubmit}>
            <div style={{ marginBottom: '10px' }}>
              <label>
                Name:
                <input
                  type="text"
                  value={editFormData.name}
                  onChange={(e) =>
                    setEditFormData({ ...editFormData, name: e.target.value })
                  }
                  required
                  style={{ marginLeft: '10px' }}
                />
              </label>
            </div>
            <div style={{ marginBottom: '10px' }}>
              <label>
                Link:
                <input
                  type="url"
                  value={editFormData.link}
                  onChange={(e) =>
                    setEditFormData({ ...editFormData, link: e.target.value })
                  }
                  required
                  style={{ marginLeft: '10px' }}
                />
              </label>
            </div>
            <button type="submit" style={{ marginRight: '10px' }}>
              Save
            </button>
            <button onClick={() => setIsEditing(false)}>Cancel</button>
          </form>
        </div>
      )}

      {/* Modal Background */}
      {isEditing && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999,
          }}
          onClick={() => setIsEditing(false)}
        ></div>
      )}
    </div>
  );
}

export default FormsList;
